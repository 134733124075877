/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumbs } from '@mui/material';
import { Home, NavigateNext } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import MainContent from '../Components/MainContent';
import TableWrapper from '../utils/TableWrapper';
import RightBar from '../Components/RightBar';
import Calendar from '../Components/calendar/Calendar';
import CommonTopTab from '../Components/CommonTopTab';
import Modal from '../Components/Modal';
import EditStudentAssignmentSubmission from '../Components/EditStudentAssignmentSubmission';

function MyAssignmentSubmissions() {
  const [editSubmissionModalOpen, setEditSubmissionModalOpen] = useState(false);
  const [submissionData, setSubmissionData] = useState(null);

  const toggleEditSubmissionModal = useCallback(() => {
    setEditSubmissionModalOpen((prev) => !prev);
  }, []);

  const student_id = useSelector((state) => state?.auth?.userDetails?.id);

  const columns = useMemo(
    () => [
      {
        header: 'Assignment name',
        accessorKey: 'assignment.title',
      },
      {
        header: 'Submission Url',
        accessorKey: 'submission_url',
        accessorFn: (row) => (
          <a
            href={row?.submission_url}
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            {row?.submission_url}
          </a>
        ),
      },
      {
        header: 'Actions',
        accessorFn: (row) => (
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm cursor-pointer hover:bg-claret-700 space-x-2 flex items-center `}
            type="button"
            onClick={() => {
              setSubmissionData(row);
              toggleEditSubmissionModal();
            }}
          >
            <p>Edit</p>
          </button>
        ),
      },
    ],
    [toggleEditSubmissionModal]
  );

  return (
    <>
      <MainContent>
        <CommonTopTab>
          <Breadcrumbs aria-label="breadcrumbs" separator={<NavigateNext />}>
            <NavLink to="/" className="flex items-center">
              <Home />
              Home
            </NavLink>
            <NavLink to="/submissions">Submissions</NavLink>
          </Breadcrumbs>
        </CommonTopTab>
        <div className="bg-white p-10 mt-4 rounded text-xs md:text-lg">
          <TableWrapper
            url={`/student-assignment-submission/filter-by-student-id/${student_id}`}
            queryKey={['student-assignment-submission', student_id]}
            errorMessage="Couldn't get your assigments submissions. Try again later"
            columns={columns}
            transform="individual-assignment"
          />
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
      <Modal
        modalOpen={editSubmissionModalOpen}
        toggleModal={toggleEditSubmissionModal}
        title={`Edit ${submissionData?.assignment?.title} Submission`}
      >
        <EditStudentAssignmentSubmission
          submissionData={submissionData}
          studentId={student_id}
          toggleModal={toggleEditSubmissionModal}
        />
      </Modal>
    </>
  );
}

export default MyAssignmentSubmissions;
